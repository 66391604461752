import React from 'react';
import PropTypes from 'prop-types';
import sc from 'styled-components';

import {Text} from '@phonectas/unifon-ui-kit';
import {StyledLabel} from '../TextDivWithLabel/styled';

const Wrapper = sc.div`
  display: flex;
  flex-direction: column;
`;

function TextWithLabel({text, label, textColor = undefined, toUpperCase = false}) {
	return (
		<Wrapper>
			<StyledLabel>{label}</StyledLabel>
			<Text style={{color: textColor}}>{toUpperCase ? text.toUpperCase() : text }</Text>
		</Wrapper>
	);
}

TextWithLabel.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	label: PropTypes.string,
	textColor: PropTypes.string,
	toUpperCase: PropTypes.bool,
};

export default TextWithLabel;
