/* eslint-disable max-len */
import React from 'react';

function SimCardPlaceholderIcon(props) {
	return (
		<svg width="68" height="49" viewBox="0 0 68 49" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 12C0 5.37258 5.37258 0 12 0H56C62.6274 0 68 5.37258 68 12V26.3302C68 29.4203 66.808 32.3913 64.6721 34.6244L54.4674 45.2942C52.2035 47.6612 49.0705 49 45.7952 49H12C5.37258 49 0 43.6274 0 37V12Z" fill="#EAEAEF"/>
			<rect x="10" y="10" width="32" height="29" rx="5" fill="white"/>
			<rect x="14" y="14" width="6" height="5" fill="#EAEAEF"/>
			<rect x="32" y="14" width="6" height="5" fill="#EAEAEF"/>
			<rect x="14" y="22" width="6" height="5" fill="#EAEAEF"/>
			<rect x="32" y="22" width="6" height="5" fill="#EAEAEF"/>
			<rect x="14" y="30" width="6" height="5" fill="#EAEAEF"/>
			<rect x="32" y="30" width="6" height="5" fill="#EAEAEF"/>
			<rect x="23" y="35" width="21" height="6" transform="rotate(-90 23 35)" fill="#EAEAEF"/>
		</svg>

	);
}

export default SimCardPlaceholderIcon;
