import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {StyledHeaderWarning, StyledShevronButton, StyledShevronIcon} from './styled';
import {
	Divider,
	IconButton,
	Layout,
	ListItem,
	Select,
	Text,
	WarningIcon,
} from '@phonectas/unifon-ui-kit';
import NewCopyIcon from 'common/components/icons/NewCopyIcon';
import CustomerLinks from '../CustomerLinks';
import CustomerCompanyInfo from '../CustomerCompanyInfo';
import CustomerDevices from '../CustomerDevices';
import {formatInfo} from 'utils/div';
import {useSmallScreen} from 'common/hooks/useScreenSize';
import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
import TextWithLabel from 'common/components/TextWithLabel';
import NewOkIcon from 'common/components/icons/NewOkIcon';
import {npsScrore} from 'utils/div';
import useThreeSixtyStore from 'common/stores/ThreeSixty';

function CustomerDetails({warnings, data}) {
	const [t] = useTranslation();
	const accountDetails = data?.account_details;
	// const isSmallSCreenHeight = useSmallScreen('(max-height:800px)');
	// const isSmallSCreenWidth = useSmallScreen('(max-width:1600px)');
	const isSmallSCreen = useSmallScreen('(max-height:800px)');
	const {setSatisfactionRating, fetchAccountById, fetchAccountFeed} = useThreeSixtyStore();
	const {callerInfo} = useThreeSixtyStore((state) => ({
		callerInfo: state.bnRedirect?.user,
		// account: state.bnRedirect?.account,
	}));
	const [showContent, setShowContent] = useState(false);
	const onShowContent = useCallback(() => setShowContent((prev) => !prev), []);
	const isNpsScore = npsScrore(accountDetails?.nps);
	const [selectValue, setSelectValue] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const handleCopyCustomerName = useCallback((e) => {
		e.stopPropagation();
		copyToClipboard(accountDetails?.name);
	}, [accountDetails?.name, copyToClipboard]);
	const options = Array.from({length: 11}, (_, i) => (i.toString()));
	const handleSatisfactionChange = useCallback((value) => {
		setSelectValue(value);
		if (value) {
			setSatisfactionRating(data?.id, value, () => {
				fetchAccountById(data?.id);
				fetchAccountFeed(data?.id);
			});
		}
	}, [fetchAccountById, data?.id, setSatisfactionRating, fetchAccountFeed]);

	useEffect(() => {
		if (isSmallSCreen) {
			setShowContent(true);
		} else {
			setShowContent(false);
		}
	}, [isSmallSCreen]);

	useEffect(() => {
		setSelectValue(accountDetails?.satisfaction_rating);
	}, [accountDetails?.satisfaction_rating]);

	return (
		<>
			{
				accountDetails?.note && (
					<StyledHeaderWarning
						direction="column"
						fullWidth
						nowrap
						// style={{minHeight: 'auto'}}
					>
						<Layout justifyContent="start" nowrap direction="row" gap="xs">
							<WarningIcon style={{alignItems: 'start'}} color="white"/>
							<Text variant="body1" color="white">{accountDetails.note}</Text>
						</Layout>
					</StyledHeaderWarning>
				)
			}
			<Layout
				fullWidth
				direction="row"
				nowrap
				gap="none"
				onClick={onShowContent}
				style={{
					cursor: 'pointer',
				}}
			>
				<Layout fullWidth direction="column" gap="sm" style={{padding: '16px'}}>
					<Layout direction="row" gap="xs" alignItems="end">
						<Text variant='h1' style={{fontSize: '30px'}}>{formatInfo(accountDetails?.name)}</Text>
						<IconButton onClick={handleCopyCustomerName} size="xs">
							<NewCopyIcon />
						</IconButton>
					</Layout>
					<Layout direction="row" gap="xs" fullWidth>
						<Text variant='body1' color="textSecondary">
							{`${t('Caller')}:`}
						</Text>
						<Text variant='body1' color="primary">
							{
								data?.id === callerInfo?.account_id ? ` ${callerInfo?.name} ( ${callerInfo?.phone_number} )` : ''
							}
						</Text>
					</Layout>
				</Layout>
				<Divider vertical/>
				<Layout
					direction="column"
					style={{minWidth: '146px', padding: '16px 0px'}}
					nowrap
					gap="xs"
					alignItems="center"
				>
					<Layout>
						<Text variant='h1' style={{fontSize: '30px', color: isNpsScore?.color}}>{isNpsScore?.score}</Text>
					</Layout>
					<Layout>
						<Text variant='body1' color="textSecondary">{t('NPS score')}</Text>
					</Layout>
					<Divider />
					<Layout
						fullWidth
						justifyContent="center"
						gap="xs"
						onClick={(e) => e.stopPropagation()}
						style={{cursor: 'default'}}
					>
						<Text variant='body1' color="textSecondary">{t('Satisfaction')}</Text>
						<Select
							onChange={handleSatisfactionChange}
							// onClick={(e) => e.stopPropagation()}
							disableSearch={true}
							value={selectValue}
						>
							{options.map((option) => (
								<ListItem key={option} value={option} primaryText={option} />
							))}
						</Select>
					</Layout>
				</Layout>
				<Layout>
					<StyledShevronButton $open={showContent}>
						<StyledShevronIcon size="xl" />
					</StyledShevronButton>
				</Layout>
			</Layout>
			{
				showContent && !accountDetails?.note && (
					<>
						<Divider fullWidth />
						<Layout
							fullWidth
							direction="row"
							style={{padding: '16px'}}
							justifyContent="space-between"
						>
							<Layout>
								{/* <TextWithLabel label={t('Number of employees')} text={formatInfo(accountDetails?.employers_count)} /> */}
								<TextWithLabel label={t('Customer responsible')} text={accountDetails?.customer_responsible?.name || ''} />
								<TextWithLabel label={t('Org. number')} text={formatInfo(accountDetails?.vat_number)} />
							</Layout>
							<Layout
								direction="column"
								gap="xs"
							>
								<Layout alignItems="center" style={{gap: '5px'}}>
									<NewOkIcon color={accountDetails?.is_vip_customer ? undefined : '#9798A9'} />
									<Text>VIP</Text>
								</Layout>
								<Layout alignItems="center" style={{gap: '5px'}}>
									<NewOkIcon color={accountDetails?.is_major_customer ? undefined : '#9798A9'} />
									<Text>{t('Major customer')}</Text>
								</Layout>
							</Layout>
						</Layout>
					</>
				)
			}
			<Divider />
			{
				!showContent && (
					<>
						<Layout
							fullWidth
							direction="row"
							style={{padding: '16px'}}
						>
							<CustomerLinks data={data} />
						</Layout>
						<Divider />
						<CustomerCompanyInfo data={data} />
						<Divider />
						<CustomerDevices data={data} />
					</>
				)
			}
		</>
	);
}

CustomerDetails.propTypes = {
	warnings: PropTypes.bool,
	data: PropTypes.object,
};

export default CustomerDetails;
