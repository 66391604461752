import React from 'react';
import PropTypes from 'prop-types';

import {Divider, Layout, Text} from '@phonectas/unifon-ui-kit';
import {useTranslation} from 'react-i18next';
import getEventType from '../../getEventTypes';

function Events({events, show}) {
	const [t] = useTranslation();

	if (!events || !events?.feed_entries) {
		return (
			<Layout direction="column" gap="none" fullWidth fullHeight>
				<Text variant="body1">{t('No events found')}</Text>
			</Layout>
		);
	}

	return (
		<Layout
			fullWidth
			fullHeight
			direction="column"
			nowrap
			autoOverflow
			id="events_wrapper"
			style={{
				paddingRight: '5px',
				paddingBottom: show ? '20px' : '0px',
				// maxHeight: '100vh',
			}}
		>
			{events?.feed_entries?.map((event, index) => {
				const EventComponent = getEventType(event.feed_type);
				return EventComponent ? (
					<Layout gap="sm" direction="column" fullWidth nowrap key={index}>
						<EventComponent key={index} data={event} id={event.id} />
						{index !== events?.feed_entries.length - 1 && <Divider />}
					</Layout>
				) : null;
			})}
		</Layout>
	);
}

Events.propTypes = {
	events: PropTypes.object,
	show: PropTypes.bool,
};

export default Events;
