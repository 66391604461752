/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function CircleArrowRigthIcon({color = '#EE5151', iconColor = 'white'}) {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z" fill={`${color}`}/>
			<path d="M2 8.3272L9.83825 8.32775L6.34514 11.829L7.51152 13L13 7.49835L7.51591 2L6.34843 3.17095L9.84155 6.67225H2.0011L2 8.3272Z" fill={`${iconColor}`}/>
		</svg>


	);
}

CircleArrowRigthIcon.propTypes = {
	color: PropTypes.string,
	iconColor: PropTypes.string,
};

export default CircleArrowRigthIcon;
