import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSmallScreen} from 'common/hooks/useScreenSize';
// import {isEqual} from 'lodash';

import {Button, Layout, Text, TextArea, TextField, Dialog} from '@phonectas/unifon-ui-kit';
import {useTranslation} from 'react-i18next';
import useDevDocAdminStore from 'common/stores/devDocAdmin';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';

function UserContent({activeId}) {
	const [t] = useTranslation();
	const isSmallScreen = useSmallScreen();
	const {verifyPassword, resetPassword} = useDevDocAdminStore();
	const data = useDevDocAdminStore((state) => state?.user);
	const isLoading = useDevDocAdminStore((state) => state.isLoading);
	const [password, setPassword] = useState('');
	const [showResetPassword, setShowResetPassword] = useState(false);
	const onShowResetPassword = useCallback(() => {
		setShowResetPassword(true);
	}, []);
	const onHideResetPassword = useCallback(() => {
		setShowResetPassword(false);
	}, []);
	const changePassword = useCallback((value) => {
		setPassword(value.target.value);
	}, []);
	const maybeLater = false;
	const onVerifyPassword = useCallback(() => {
		verifyPassword(activeId, password, () => {
			setPassword('');
		});
	}, [password, activeId, verifyPassword]);
	const onResetPassword = useCallback(() => {
		resetPassword(activeId, password, () => {
			setPassword('');
			onHideResetPassword();
		});
	}, [password, activeId, resetPassword, onHideResetPassword]);

	useEffect(() => {
		if (activeId) {
			setPassword('');
		}
	}, [activeId]);


	if (!activeId) {
		return;
	}

	// const showSaveBtn = !isEqual(tempData, data);

	return (
		<Layout fullWidth fullHeight nowrap direction="column" justifyContent="space-between">
			<Layout gap="lg" direction="column" fullWidth>
				<Layout direction="row" fullWidth justifyContent="space-between" nowrap>
					{
						isSmallScreen ? (
							<Text variant="h1" style={{textDecoration: 'underline'}}>{t('Details')}</Text>
						) : null
					}
				</Layout>
				<Layout style={{width: '300px'}}>
					<TextField
						label={t('Name')}
						value={data?.username}
						// onChange={(e) => updateField('email', e.target.value)}
						fullWidth
						// disabled
					/>
				</Layout>
				<Layout style={{width: '300px'}}>
					<TextField
						label={t('Password')}
						value={password}
						onChange={changePassword}
						fullWidth
					/>
				</Layout>
				<Layout fullWidth>
					<Button
						variant="secondary"
						disabled={!password}
						onClick={onShowResetPassword}
					>
						{t('Reset Password')}
					</Button>
					<Button
						variant="secondary"
						disabled={!password}
						onClick={onVerifyPassword}
						// color={isVerifyed ? 'success' : 'error'}
					>
						{t('Verify')}
					</Button>
				</Layout>
				{
					maybeLater && (
						<TextArea
							fullWidth
							style={{height: '200px'}}
							// onChange={(e) => updateField('note', e.target.value)}
							// value={tempData?.note}
							label={t('Notes')}
						/>
					)
				}
			</Layout>
			{
				// showSaveBtn && (
				// 	<Layout gap="none" fullWidth>
				// 		<StyledDivider fullWidth />
				// 		<Button color="success" variant="primary">{t('Save')}</Button>
				// 		<Button onClick={onCancel} color="secondary" variant="default">{t('Cancel')}</Button>
				// 	</Layout>
				// )
			}
			<Dialog
				show={showResetPassword}
				onClose={onHideResetPassword}
				onOk={onResetPassword}
				title={t('Reset password for {{name}}', {name: data?.username})}
			/>
			<LoaderPortal isLoading={isLoading} />
		</Layout>
	);
}

UserContent.propTypes = {
	activeId: PropTypes.number,
};

export default UserContent;
