import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import NavLink from './components/NavLink';
import {ROUTES} from 'common/constants';
import useUserStore from 'common/stores/user';

import {
	Layout, Drawer, SettingsIcon, ProfileIcon,
	AdminIcon,
} from '@phonectas/unifon-ui-kit';
// import BackofficeIcon from 'common/components/icons/BackOfficeIcon';
import {ROLES} from 'common/constants';
import ThreeSixtyIcon from 'common/components/icons/ThreeSixtyIcon';

/**
 * @param {boolean} expand
 * @return {JSX.Element}
 * @constructor
 */
function MainNav({expand}) {
	const [t] = useTranslation();
	const {user} = useUserStore();
	const isAdmin = user?.roles.includes(ROLES.ADMIN);
	const isBmo = user?.roles.includes(ROLES.BMO);
	const isLargeaccount = user?.roles.includes(ROLES.LARGEACC);
	const isCustomerService = user?.roles.includes(ROLES.CUSTOMER_SERVICE);

	const routes = useMemo(() => {
		const routesArray = [
			{
				to: ROUTES.PROFILE,
				Icon: ProfileIcon,
				text: t('Profile'),
				size: 'xl',
			},
			...(isCustomerService || isLargeaccount || isBmo ? [{
				to: ROUTES.BMODASHBOARD,
				Icon: SettingsIcon,
				text: t('BMO'),
				size: 'xl',
			}] : []),
			...(isCustomerService || isLargeaccount ? [{
				to: ROUTES.THREE_SIXTY,
				Icon: ThreeSixtyIcon,
				text: t('Unifon 360'),
				size: 'xl',
			}] : []),
			...(isCustomerService ? [{
				to: ROUTES.DEV_DOC,
				Icon: AdminIcon,
				text: t('Dev admin'),
				size: 'xl',
			}] : []),
		];

		return routesArray;
	}, [t, isAdmin, isLargeaccount, isCustomerService, isBmo, user]);

	return (
		<Drawer
			expand={expand}
			variant="permanent"
			finalSize={225}
			initialSize={69}
		>
			<Layout direction="column" gap="none" fullWidth>
				{routes.map(({to, Icon, text, size}) => (
					<NavLink to={to} key={to} Icon={Icon} text={text} size={size} expand={expand} />
				))}
			</Layout>
		</Drawer>
	);
}

MainNav.propTypes = {
	expand: PropTypes.bool.isRequired,
};

export default MainNav;
