import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {StyledSectionDiv} from '../../../styled';
import {Layout, Table} from '@phonectas/unifon-ui-kit';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import ChangeRespDialog from './ChangeRespDialog';
import CustomerConfigRow from './CustomerConfigRow';
import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';

function CustomerConfig({customerId}) {
	const [t] = useTranslation();
	const {setCustomerResp, fetchAccountById, fetchAccountFeed} = useThreeSixtyStore();
	const activeAccount = useThreeSixtyStore((state) => state.activeAccount);
	const activeAccountData = activeAccount?.account_details;
	const getCustomerResp = activeAccountData?.customer_responsible?.name ?
		activeAccountData.customer_responsible.name : activeAccountData?.customer_resp_est;
	const [respDialog, setRespDialog] = useState(false);
	const onOpenRespDialog = useCallback(() => setRespDialog(true), []);
	const onCloseRespDialog = useCallback(() => setRespDialog(false), []);
	const onOkRespDialog = useCallback((catId, firstname, lastname ) => {
		setCustomerResp(activeAccount?.id, catId, firstname, lastname);
		setTimeout(() => {
			fetchAccountById(activeAccount?.id);
			fetchAccountFeed(activeAccount?.id);
		}, 1400);
		fetchAccountById(activeAccount?.id);
		onCloseRespDialog();
	}, [activeAccount?.id, setCustomerResp, onCloseRespDialog, fetchAccountById, fetchAccountFeed]);
	const handleInputChange = useCallback((e) => {
		e.preventDefault();
	}, []);
	const inputFields = useMemo(() => [
		{
			label: t('Customer responsible'),
			value: getCustomerResp,
			id: customerId,
			button: t('Change'),
			onClick: onOpenRespDialog,
			disabled: false,
		},
		{
			label: t('Binding'),
			value: getDateByCustomFormat(activeAccountData?.lockin_end, DATE_FORMATS.DD_MM_YY),
			id: customerId,
			onChange: handleInputChange,
			button: t('Change'),
			disabled: true,
		},
	], [t, getCustomerResp, onOpenRespDialog, handleInputChange, customerId, activeAccountData?.lockin_end]);

	const renderItems = useCallback((item, index) => {
		return (
			<CustomerConfigRow
				key={index}
				data={item}
				onClick={item.onClick}
			/>
		);
	}, []);

	const showContent = false;

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Layout direction="column">
				{
					showContent && (
						<Table
							items={inputFields}
							renderItem={(item, index) => renderItems(item, index)}
						>
							<Table.Row>
								<Table.Cell width={250}>{t('Setting')}</Table.Cell>
								<Table.Cell width={290}>{t('value')}</Table.Cell>
								<Table.Cell width={106}>{t('Action')}</Table.Cell>

							</Table.Row>
						</Table>
					)
				}
			</Layout>
			<ChangeRespDialog
				show={respDialog}
				onClose={onCloseRespDialog}
				title={t('Change customer responsible')}
				onOk={onOkRespDialog}
				currentResp={getCustomerResp}
			>
			</ChangeRespDialog>
		</StyledSectionDiv>
	);
}

CustomerConfig.propTypes = {
	customerId: PropTypes.number,
};

export default CustomerConfig;
