import React from 'react';
import PropTypes from 'prop-types';

import {StyledPillBadge} from './styled';
import {convertHoursSla} from 'utils/date';

function Badges({data}) {
	const badgeColor = () => {
		if (data >= 8) {
			return '#27AD61';
		} else if (data > 0 && data < 8) {
			return '#F7AB20';
		} else {
			return '#EB5757';
		}
	};
	return (
		<StyledPillBadge bg={badgeColor()}>
			{convertHoursSla(data)}
		</StyledPillBadge>
	);
}

Badges.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
};

export default Badges;
