import sc from 'styled-components';

export const DataUsageContainer = sc.div`
  background-color: rgba(135, 141, 230, 0.25);
  border-radius: 5px;
  overflow: hidden; 
  height: 8px; 
  margin: 5px 0;
//   max-width: 120px;
    width: 50px;
    display: flex;
    align-items: center;
`;

export const DataUsageFill = sc.div`
  background-color: rgba(135, 141, 230, 1); 
  height: 100%; 
  transition: width 0.3s ease;
`;
