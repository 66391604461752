import React from 'react';
import PropTypes from 'prop-types';
import {Layout, Text} from '@phonectas/unifon-ui-kit';
import {StyledTextDiv, StyledLabel} from './styled';


function TextDivWithLabel({
	label,
	text,
	secondaryText,
	noBorder = false,
	smBorderRadius,
	whiteSpaceNormal = false,
}) {
	return (
		<>
			<Layout fullWidth gap="sm">
				{label && (
					<StyledLabel variant="body1">
						{label}
					</StyledLabel>
				)}
				<StyledTextDiv
					$noBorder={noBorder}
					$smBorderRadius={smBorderRadius}
					$whiteSpaceNormal={whiteSpaceNormal}
				>
					<Text variant="body1">{text}</Text>
					{
						secondaryText && (
							<Text variant="body1">{secondaryText}</Text>
						)
					}
				</StyledTextDiv>
			</Layout>
		</>
	);
}

TextDivWithLabel.propTypes = {
	text: PropTypes.string.isRequired,
	label: PropTypes.string,
	password: PropTypes.bool,
	noBorder: PropTypes.bool,
	smBorderRadius: PropTypes.bool,
	whiteSpaceNormal: PropTypes.bool,
	secondaryText: PropTypes.string,
};

export default TextDivWithLabel;
