/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function CircleArrowLeftIcon({color = '#EE5151', iconColor = 'white'}) {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z" fill={`${color}`}/>
			<path d="M13 6.6728L5.16175 6.67225L8.65486 3.17095L7.48848 2L2 7.50165L7.48409 13L8.65157 11.8291L5.15845 8.32775L12.9989 8.32775L13 6.6728Z" fill={`${iconColor}`}/>
		</svg>

	);
}

CircleArrowLeftIcon.propTypes = {
	color: PropTypes.string,
	iconColor: PropTypes.string,
};

export default CircleArrowLeftIcon;
