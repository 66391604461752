import React from 'react';
import PropTypes from 'prop-types';

function StatusDot({color = '#47AC45'}) {
	return (
		<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="4" cy="4.5" r="4" fill={`${color}`}/>
		</svg>

	);
}

StatusDot.propTypes = {
	color: PropTypes.string,
};

export default StatusDot;
