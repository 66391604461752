export const MIME_TYPE = {
	PNG: 'image/png',
	JPEG: 'image/jpeg',
};

export const FILES_LIMIT = 3;
export const MAX_FILE_SIZE = 10000000;

export async function blobToBase64(blob) {
	return new Promise((resolve, reject) => {
		if (blob) {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				resolve(reader.result);
			};
		} else reject(new Error('No blob specified'));
	});
}

export function fileSizeToMB(sizeInBytes) {
	return (sizeInBytes / (1024 * 1024)).toFixed(2);
}

export function formatFileSize(bytes) {
	if (!bytes) return '';
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function convertMB(mb) {
	if (!mb) return '';
	const bytes = mb * 1024 * 1024;
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	const valueInString = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	return {valueInMB: mb, valueInString};
}

// remake this to be more dynamic
export function createFormData(form) {
	const formData = new FormData();

	if (form.files.length > 0) {
		for (let i = 0; i < form.files.length; i++) {
			formData.append('files', form.files[i]);
		}
	}

	formData.append('description', form.description);
	formData.append('severity', form.severity);
	formData.append('ticketType', form.ticketType);
	formData.append('customer.reportedByName', form.customer.reportedByName);
	formData.append('customer.reportedByPhoneNumber', form.customer.reportedByPhoneNumber);
	formData.append('faultyService.serviceType', form.faultyService.serviceType);
	formData.append('faultyService.identifier', form.faultyService.identifier);
	formData.append('identifierType.id', form.identifierType.id);
	formData.append('zendeskRef', form.zendeskRef);

	form.notificationEmails.forEach((email) => {
		formData.append('notificationEmails', email);
	});
	return formData;
}

