import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {Table} from '@phonectas/unifon-ui-kit';
import {StyledSectionDiv} from '../../../styled';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {LIMIT_PAYLOAD} from 'common/constants';
import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';

function InvoiceTable({data, id}) {
	const [t] = useTranslation();
	const {fetchInvoices} = useThreeSixtyStore();
	const {invoices, totalCount, accountNotFound} = useThreeSixtyStore((state) => ({
		invoices: state.accountTables?.invoices,
		totalCount: state.accountTables?.invoices?.total_count,
		accountNotFound: state.accountNotFound,
	}));
	const invoicesData = invoices?.invoices;
	// const onRowClick = useCallback((item) => {
	// 	console.log(item.org_nr);
	// 	// navigate(`/unifon_360/customer?id=${item.reg_nr}`);
	// }, [onRowClick]);
	const onLoadMore = useCallback((size) => {
		if (totalCount <= size) return;

		fetchInvoices(size + LIMIT_PAYLOAD, id, '', '');
	}, [id, totalCount, fetchInvoices]);
	const renderItem = useCallback((item, index) => (
		<Table.Row
			key={index}
			// onClick={() => onRowClick(item)}
			selected={item.index === index}
			index={index}
		>
			<Table.Cell>{item?.org_nr}</Table.Cell>
			<Table.Cell>{item?.invoice_number}</Table.Cell>
			<Table.Cell>{item?.is_open ? t('Open') : t('Closed')}</Table.Cell>
			<Table.Cell>{getDateByCustomFormat(item?.close_date, DATE_FORMATS.DD_MM_YY)}</Table.Cell>
			<Table.Cell>{item?.total}</Table.Cell>
			<Table.Cell>{item?.paid_amount}</Table.Cell>
			<Table.Cell>{getDateByCustomFormat(item?.due_date, DATE_FORMATS.DD_MM_YY)}</Table.Cell>
		</Table.Row>
	), [t]);

	useEffect(() => {
		if (accountNotFound) return;

		if (totalCount === 0 && invoices?.invoices === null) return;
		const isAccountIdMatched = invoicesData?.length > 0 && id === invoicesData[0]?.account_id;
		const isUsersFetched = invoices !== null && isAccountIdMatched;
		// må legge til account_id og retur av filters i fetchInvoices
		// const isUsersFilterMatched = invoices?.filters?.account_id === id && invoicesData === null;

		// if (isUsersFetched || isUsersFilterMatched) return;
		if (isUsersFetched) return;

		fetchInvoices(LIMIT_PAYLOAD, id);
	}, [id, invoicesData, accountNotFound, fetchInvoices, invoices, totalCount]);

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Table
				style={{
					width: '100%',
					overflow: 'auto',
					height: '100%',
					borderRadius: '8px',
				}}
				items={invoicesData || []}
				renderItem={(item, index) => renderItem(item, index)}
				virtualized
				onScrollDown={onLoadMore}
			>
				<Table.Row style={{position: 'sticky', top: '0px'}}>
					<Table.Cell>{t('Name')}</Table.Cell>
					<Table.Cell>{t('Invoice nr')}</Table.Cell>
					<Table.Cell>{t('Open')}</Table.Cell>
					<Table.Cell>{t('Closed date')}</Table.Cell>
					<Table.Cell>{t('Total')}</Table.Cell>
					<Table.Cell>{t('Payed')}</Table.Cell>
					<Table.Cell>{t('Payed date')}</Table.Cell>
				</Table.Row>
			</Table>
		</StyledSectionDiv>
	);
}

InvoiceTable.propTypes = {
	data: PropTypes.array,
	id: PropTypes.string.isRequired,
};

export default InvoiceTable;
