import React from 'react';
import PropTypes from 'prop-types';

import {Layout, Text} from '@phonectas/unifon-ui-kit';
import {serviceStatusesColor} from 'utils/div';
import StatusOk from '../icons/StatusOk';
import StatusDot from '../icons/StatusDot';
import CircleXIcon from '../icons/CircleXIcon';
import CircleArrowRigthIcon from '../icons/CircleArrowRigthIcon';
import CircleIcon from '../icons/CircleIcon';
import CircleArrowLeftIcon from '../icons/CircleArrowLeftIcon';

function TemplateStatusColor({statusCode, compact = false}) {
	const {text, priColor, secColor, icon} = serviceStatusesColor(statusCode);
	let iconComponent = null;
	let textColor = null;
	switch (icon) {
	case 'active':
		iconComponent = <StatusOk priColor={priColor} secColor={secColor}/>;
		textColor = priColor;
		break;
	case 'sperret':
		iconComponent = <CircleXIcon color={priColor} secColor={secColor}/>;
		textColor = priColor;
		break;
	case 'underInportering':
		iconComponent = <CircleArrowRigthIcon color={priColor} secColor={secColor}/>;
		textColor = secColor;
		break;
	case 'reservert':
		iconComponent = <CircleIcon color={priColor}/>;
		textColor = secColor;
		break;
	case 'underUtportering':
		iconComponent = <CircleArrowLeftIcon color={priColor} secColor={secColor}/>;
		textColor = secColor;
		break;
	case 'utPortert':
		iconComponent = <CircleIcon color={priColor}/>;
		textColor = secColor;
		break;
	default:
		iconComponent = <CircleIcon color={priColor}/>;
		textColor = secColor;
		break;
	}

	return (
		<Layout alignItems="center" gap="sm">
			{
				!compact ? (
					[iconComponent]
				) : <StatusDot color={priColor}/>
			}
			<Text variant="body1" style={{color: textColor}}>{text}</Text>
		</Layout>
	);
}

TemplateStatusColor.propTypes = {
	statusCode: PropTypes.string,
	compact: PropTypes.bool,
};

export default TemplateStatusColor;
