import React from 'react';
import PropTypes from 'prop-types';

import {Table, Tooltip} from '@phonectas/unifon-ui-kit';
import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';
import {truncate} from 'lodash';
import Badges from 'common/components/Badges';

function ZendeskTableRow({data}) {
	return (
		<Table.Row>
			<Table.Cell>{data?.case_id ? data.case_id : ''}</Table.Cell>
			<Table.Cell>{data?.status ? data.status : ''}</Table.Cell>
			<Table.Cell>
				<span style={{width: '100%', height: '100%'}}>
					{
						data?.subject ? (
							<Tooltip
								arrow
								text={data.subject}
							>
								<span style={{cursor: 'pointer'}}>
									{truncate(data.subject, {length: 20})}
								</span>
							</Tooltip>
						) : ''
					}
				</span>
			</Table.Cell>
			<Table.Cell>{data?.submitter?.name ? data.submitter.name : ''}</Table.Cell>
			<Table.Cell>{data?.assignee?.name ? data.assignee.name : ''}</Table.Cell>
			<Table.Cell>{data?.group ? data.group : ''}</Table.Cell>
			<Table.Cell>{data?.sla ? <Badges data={data?.sla} /> : 'N/A'}</Table.Cell>
			<Table.Cell>{getDateByCustomFormat(data?.ze_updated_at, DATE_FORMATS.DD_MM_YY)}</Table.Cell>
			<Table.Cell>{getDateByCustomFormat(data?.ze_created_at, DATE_FORMATS.DD_MM_YY)}</Table.Cell>
		</Table.Row>
	);
}

ZendeskTableRow.propTypes = {
	data: PropTypes.object,
};

export default React.memo(ZendeskTableRow);
