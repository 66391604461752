import React from 'react';
import useUserStore from 'common/stores/user';
import {useTranslation} from 'react-i18next';
import UserInfo from 'common/components/UserInfo';

import {Layout, Page, Text} from '@phonectas/unifon-ui-kit';
// import BmoUserTable from './bmoProfile/BmoUserTable';


function UserProfile() {
	const user = useUserStore((state) => state.user);
	const [t] = useTranslation();

	return (
		<>
			<Page.Section>
				<Layout direction="column" nowrap autoOverflow alignItems="center">
					<UserInfo data={user} />
				</Layout>
			</Page.Section>
			<Page.Section>
				<Layout fullWidth direction="column" gap="md">
					<Layout direction="column" nowrap autoOverflow alignItems="center">
						<Text variant="h1">Unifon 360</Text>
					</Layout>
					<Layout direction="row" nowrap autoOverflow alignItems="center">
						<Text variant="body1">{t('Setting up Bedriftsnett:')}</Text>
						<Text variant="body1">{t('https://ks.unifon.no/Unifon360/n/${caller}')}</Text>
					</Layout>
				</Layout>
			</Page.Section>
		</>
	);
}

export default UserProfile;
