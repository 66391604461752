import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import SearchWidget from 'common/components/SearchWidget';
import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {ROUTES} from 'common/constants';

function RenderItemDetails({data}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const dataTable = useThreeSixtyStore((state) => state?.activeCustomerResp);
	const [searchQuery, setSearchQuery] = useState('');
	const handleSearchQuery = useCallback((value) => {
		setSearchQuery(value);
	}, []);
	const handleCrearQuery = useCallback(() => {
		setSearchQuery('');
	}, []);
	const handleClickedRow = useCallback((item) => {
		navigate(`${ROUTES.THREE_SIXTY}/${item?.id}`);
	}, [navigate]);
	const renderItem = useCallback((item, index) => (
		<Table.Row
			key={index}
			index={index}
			onClick={() => handleClickedRow(item)}
		>
			<Table.Cell>{item?.name}</Table.Cell>
			<Table.Cell>{item?.vat_number}</Table.Cell>
		</Table.Row>
	), [dataTable]);

	const filteredList = dataTable?.accounts?.filter((item) => {
		return item.name.toLowerCase().includes(searchQuery.toLowerCase());
	});

	return (
		<Layout fullWidth fullHeight style={{overflow: 'hidden'}} gap="sm">
			<Layout
				direction="row"
				fullWidth
				nowrap
				justifyContent="space-between"
				alignItems="center"
				style={{overflow: 'hidden'}}
			>
				<Layout fullWidth direction="column">
					<Text variant="h4">{data?.name}</Text>
					<Layout style={{width: '300px'}}>
						<SearchWidget
							placeholder="Search"
							onChange={handleSearchQuery}
							clearSearch={handleCrearQuery}
							value={searchQuery}
						/>
					</Layout>
				</Layout>
			</Layout>
			<Table
				items={filteredList || []}
				renderItem={renderItem}
				style={{overflow: 'auto', paddingBottom: '120px'}}
			>
				<Table.Row>
					<Table.Cell>{t('Customer')}</Table.Cell>
					<Table.Cell width={95}>{t('Org. number')}</Table.Cell>
				</Table.Row>
			</Table>
		</Layout>
	);
}

RenderItemDetails.propTypes = {
	data: PropTypes.object,
};

export default RenderItemDetails;
