import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
// import UserModel from 'common/models/user';
// import AuthService from 'services/auth';
import Immutable from 'immutable';
// import ThreeSixtyModel from '../models/threeSixty.model';
import {
	LIMIT_PAYLOAD,
	ALIAS_HEADER,
	ALIAS_HEADER_TWO,
} from 'services/constants';
import {showError, showSuccess} from '@phonectas/unifon-ui-kit';
import {getI18n} from 'react-i18next';
import {getBnApiUrl} from 'services/constants';
import {threeSixtyApiUrl} from 'services/services';
import {USERS_DEFAULT_FILTERS} from '../constants';

// Utility function to apply middleware conditionally
// const applyDevtools = (config) => (process.env.NODE_ENV !== 'production' ? devtools(config) : config);

const useThreeSixtyStore = create(devtools((set) => ({
	isLoading: false,
	accounts: null,
	accountNotFound: false,
	activeAccount: null,
	activePhone: null,
	accountFeed: null,
	activeRow: null,
	accountTables: {
		billingGroups: null,
		contacts: null,
		users: null,
		zendeskCases: null,
		isLoadingTable: false,
		parentId: null,
		invoices: null,
	},
	queryResaults: null,
	currentUser: null,
	bnRedirect: {
		account: null,
		user: null,
	},
	customerResponibles: null,
	activeCustomerResp: null,
	satisfactionRating: null,
	resetAccounts: () => set({accounts: null}, false, 'THREE_SIXTY/resetAccounts'),
	setBnRedirect: (value) => set((state) => ({
		bnRedirect: {
			...state.bnRedirect,
			...value,
		},
	}), false, 'THREE_SIXTY/setBnRedirect'),
	resetBnRedirect: () => set({bnRedirect: {account: null, user: null}}, false, 'THREE_SIXTY/resetBnRedirect'),
	resetQueryResults: () => set({queryResaults: null}, false, 'THREE_SIXTY/RESET_QUERY_RESULTS'),
	setCurrentUser: (user) => set(() => ({currentUser: user}), false, 'THREE_SIXTY/setCurrentUser'),
	setActiveRow: (row) => set(() => ({
		activeRow: row,
	}), false, 'THREE_SIXTY/setActiveRow'),
	fetchList: async (limit = LIMIT_PAYLOAD, searchText, sort) => {
		try {
			set({isLoading: true}, false, 'THREE_SIXTY/isLoading');
			const response = await fetch(`${threeSixtyApiUrl}accounts/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						search_text: searchText,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set({accounts: json, isLoading: false}, false, 'THREE_SIXTY/setList');
			} else {
				set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			}
		} catch (err) {
			set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			console.error(err);
		}
	},
	fetchAccountById: async (id) => {
		try {
			set({isLoading: true}, false, 'THREE_SIXTY/isLoading');
			const response = await fetch(`${threeSixtyApiUrl}account/id/${id}`, {
				headers: ALIAS_HEADER,
				method: 'GET',
			});
			const json = await response.json();
			if (response.ok) {
				set({activeAccount: json, isLoading: false, accountNotFound: false}, false, 'THREE_SIXTY/setAccountId');
				set;
			} else {
				set({isLoading: false, accountNotFound: true}, false, 'THREE_SIXTY/isLoading');
				showError({
					message: getI18n().t(
						'{{status}} - {{msg}}',
						{
							status: response.status,
							msg: json.message,
						},
					),
				});
			}
		} catch (err) {
			set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			console.error(err);
		}
	},
	fetchAccountByPhone: async (phone, onSuccess) => {
		try {
			set({isLoading: true}, false, 'THREE_SIXTY/isLoading');
			const response = await fetch(`${threeSixtyApiUrl}account/phone/${phone}`, {
				headers: ALIAS_HEADER,
				method: 'GET',
			});
			const json = await response.json();
			if (response.ok) {
				set((state) => ({
					bnRedirect: {
						...state.bnRedirect,
						account: json,
					},
					isLoading: false,
					accountNotFound: false,
				}), false, 'THREE_SIXTY/setBnRedirect');
				onSuccess && onSuccess();
			} else {
				set({isLoading: false, accountNotFound: true}, false, 'THREE_SIXTY/isLoading');
				showError({
					message: getI18n().t(
						'{{status}} - {{msg}}',
						{
							status: response.status,
							msg: json.message,
						},
					),
				});
			}
		} catch (err) {
			set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			console.error(err);
		}
	},
	fetchAccountFeed: async (id, feedType = '', limit = LIMIT_PAYLOAD, sort = '') => {
		try {
			set({isLoading: true}, false, 'THREE_SIXTY/isLoading');
			const response = await fetch(`${threeSixtyApiUrl}accountfeed/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: id,
						feed_type: feedType,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set({accountFeed: json, isLoading: false}, false, 'THREE_SIXTY/AccountFeed');
			} else {
				set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			}
		} catch (err) {
			set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			console.error(err);
		}
	},
	fetchBillingGroups: async (limit = LIMIT_PAYLOAD, accountId, searchText = '', sort) => {
		try {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: true,
				},
			}), false, 'THREE_SIXTY/BillingGroups_loading');
			const response = await fetch(`${threeSixtyApiUrl}billinggroups/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: accountId,
						search_text: searchText,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					accountTables: {
						...state.accountTables,
						billingGroups: json,
					},
				}), false, 'THREE_SIXTY/BillingGroupsSet');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: false,
				},
			}), false, 'THREE_SIXTY/BillingGroups_loading');
		}
	},
	fetchContacts: async (limit = LIMIT_PAYLOAD, accountId, searchText = '', sort) => {
		try {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: true,
				},
			}), false, 'THREE_SIXTY/Contacts_loading');
			const response = await fetch(`${threeSixtyApiUrl}contacts/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: accountId,
						search_text: searchText,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					accountTables: {
						...state.accountTables,
						isLoadingTable: false,
						contacts: json,
					},
				}), false, 'THREE_SIXTY/Contacts_loading');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: false,
				},
			}), false, 'THREE_SIXTY/Contacts_loading');
		}
	},
	fetchUsers: async (limit = LIMIT_PAYLOAD, accountId, searchText = '', parent, statuses = true, sort) => {
		try {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: true,
				},
			}), false, 'THREE_SIXTY/USERS_LOADING');
			const response = await fetch(`${threeSixtyApiUrl}users/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: accountId,
						search_text: searchText,
						cdr_parent_id: parent,
						statuses: statuses ? USERS_DEFAULT_FILTERS : null,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					accountTables: {
						...state.accountTables,
						users: json,
						isLoadingTable: false,
					},
				}), false, 'THREE_SIXTY/SET_USERS');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: false,
				},
			}), false, 'THREE_SIXTY/USERS_LOADING');
		}
	},
	fetchUser: async (numb, onSuccess) => {
		const limit = LIMIT_PAYLOAD;
		try {
			// set((state) => ({
			// 	accountTables: {
			// 		...state.accountTables,
			// 		isLoadingTable: true,
			// 	},
			// }), false, 'THREE_SIXTY/USERS_LOADING');
			const response = await fetch(`${threeSixtyApiUrl}users/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						phone_number: numb,
					},
					limit: limit,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					bnRedirect: {
						...state.bnRedirect,
						user: json?.customer_users[0],
					},
					isLoading: false,
				}), false, 'THREE_SIXTY/SetUser');
				onSuccess && onSuccess();
			}
		} catch (err) {
			console.error(err);
		} finally {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: false,
				},
			}), false, 'THREE_SIXTY/USERS_LOADING');
		}
	},
	fetchUserParentId: async (limit = LIMIT_PAYLOAD, accountId, parent, includeChild) => {
		try {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: true,
				},
			}), false, 'THREE_SIXTY/USERS_LOADING');
			const response = await fetch(`${threeSixtyApiUrl}users/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: accountId,
						cdr_parent_id: parent,
						include_child_cards: includeChild,
					},
					limit: limit,
					// sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					accountTables: {
						...state.accountTables,
						parentId: json,
						isLoadingTable: false,
					},
				}), false, 'THREE_SIXTY/SET_PARENT_ID');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: false,
				},
			}), false, 'THREE_SIXTY/USERS_PARENT_LOADING');
		}
	},
	fetchZendeskCases: async (limit = LIMIT_PAYLOAD, accountId, searchText = '', sort) => {
		try {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: true,
				},
			}), false, 'THREE_SIXTY/ZENDESK_LOADING');
			const response = await fetch(`${threeSixtyApiUrl}zendesk/cases/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: accountId,
						search_text: searchText,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					accountTables: {
						...state.accountTables,
						zendeskCases: json,
						isLoadingTable: false,
					},
				}), false, 'THREE_SIXTY/SetZENDESK_CASES');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: false,
				},
			}), false, 'THREE_SIXTY/ZENDESK_LOADING');
		}
	},
	fetchZendeskUsers: async (limit = LIMIT_PAYLOAD, accountId, searchText = '', sort) => {
		try {
			set({isLoading: true}, false, 'THREE_SIXTY/isLoading');
			const response = await fetch(`${threeSixtyApiUrl}zendesk/users/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: accountId,
						search_text: searchText,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set({zendeskUsers: json, isLoading: false}, false, 'THREE_SIXTY/setZENDESK_USERS');
			} else {
				set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			}
		} catch (err) {
			set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
			console.error(err);
		}
	},
	setCustomerResp: async (accountId, catId, firstName, lastName, onSuccess) => {
		try {
			const response = await fetch(`${threeSixtyApiUrl}account/${accountId}/customerresp`, {
				headers: ALIAS_HEADER_TWO,
				method: 'POST',
				body: JSON.stringify({
					catalog_id: catId,
					// entra_id: entraId,
					name: `${firstName} ${lastName}`,
				}),
			});
			if (response.ok) {
				showSuccess({
					message: getI18n().t(
						'Responsible changed to {{name}}',
						{
							name: `${firstName} ${lastName}`,
						},
					),
				});
				onSuccess && onSuccess();
			} else {
				showError({
					message: getI18n().t(
						'Error changing responsible',
					),
				});
			}
		} catch (err) {
			console.error(err);
		}
	},
	delCustomerResp: async (id, onSuccess) => {
		try {
			const response = await fetch(`${threeSixtyApiUrl}account/${id}/customerresp`, {
				headers: ALIAS_HEADER,
				method: 'DELETE',
			});
			if (response.ok) {
				showSuccess({
					message: getI18n().t(
						'Responsible deleted',
					),
				});
				onSuccess && onSuccess();
			}
		} catch (err) {
			console.error(err);
		}
	},
	addEventComment: async (id, comment, onSuccess) => {
		try {
			const response = await fetch(`${threeSixtyApiUrl}account/${id}/comment`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify(
					{comment: comment},
				),
			});
			if (response.ok) {
				onSuccess && onSuccess();
				showSuccess({
					message: getI18n().t(
						'Comment added',
					),
				});
			} else {
				showError({
					message: getI18n().t(
						'Error adding comment',
					),
				});
			}
		} catch (err) {
			console.error(err);
		}
	},
	deleteEventComment: async (id, onSuccess) => {
		try {
			const response = await fetch(`${threeSixtyApiUrl}comment/${id}`, {
				headers: ALIAS_HEADER,
				method: 'DELETE',
			});
			if (response.ok) {
				onSuccess && onSuccess();
				showSuccess({
					message: getI18n().t(
						'Comment deleted',
					),
				});
			} else {
				showError({
					message: getI18n().t(
						'Error deleting comment',
					),
				});
			}
		} catch (err) {
			console.error(err);
		}
	},
	responsibleSearch: async (searchText) => {
		try {
			const response = await fetch(`${getBnApiUrl()}catalog/search`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					// filter: {
					// 	// catalog_type: '',
					// 	// company: [],
					// 	// department_id: [],
					// 	// department_name: [],
					// 	// email: [],
					// 	// favorite: false,
					// 	// firstname: [searchText],
					// 	// fixed: [],
					// 	// lastname: [searchText],
					// 	// mobile: [],
					// 	// tags: [],
					// 	// title: [],
					// 	// company: [`Unifon`],
					// },
					order_by: 'firstname,lastname',
					wildcard: searchText,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set({queryResaults: json}, false, 'THREE_SIXTY/QUERY_RESULTS');
			}
		} catch (err) {
			console.error(err);
		}
	},
	majorResponsibleList: async (limit = LIMIT_PAYLOAD, catalogId, entraId, searchText = '', sort = 'nameAsc') => {
		try {
			set({isLoading: true}, false, 'THREE_SIXTY/isLoading');
			const response = await fetch(`${threeSixtyApiUrl}customerresps/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						customer_resp_catalog_id: catalogId,
						customer_resp_entra_id: entraId,
						search_text: searchText,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set({customerResponibles: json, isLoading: false}, false, 'THREE_SIXTY/setList');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
		}
	},
	majorResponsibleSearch: async (catalogId, searchText = '') => {
		try {
			set({isLoading: true}, false, 'THREE_SIXTY/isLoading');
			const response = await fetch(`${threeSixtyApiUrl}accounts/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						customer_resp_catalog_id: catalogId,
						// customer_resp_entra_id: entraId,
						search_text: searchText,
					},
					limit: LIMIT_PAYLOAD,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set({activeCustomerResp: json, isLoading: false}, false, 'THREE_SIXTY/setList');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set({isLoading: false}, false, 'THREE_SIXTY/isLoading');
		}
	},
	fetchInvoices: async (limit = LIMIT_PAYLOAD, accountId, searchText = '', sort) => {
		try {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: true,
				},
			}), false, 'THREE_SIXTY/Invoices_loading');
			const response = await fetch(`${threeSixtyApiUrl}invoices/list`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					filters: {
						account_id: accountId,
						search_text: searchText,
					},
					limit: limit,
					sort_mode: sort,
				}),
			});
			if (response.ok) {
				const json = await response.json();
				set((state) => ({
					accountTables: {
						...state.accountTables,
						invoices: json,
					},
				}), false, 'THREE_SIXTY/InvoicesSet');
			}
		} catch (err) {
			console.error(err);
		} finally {
			set((state) => ({
				accountTables: {
					...state.accountTables,
					isLoadingTable: false,
				},
			}), false, 'THREE_SIXTY/Invoices_loading');
		}
	},
	setSatisfactionRating: async (accountId, body, onSuccess) => {
		try {
			const response = await fetch(`${threeSixtyApiUrl}account/${accountId}/satisfactionrating`, {
				headers: ALIAS_HEADER,
				method: 'POST',
				body: JSON.stringify({
					satisfaction_rating: body,
				}),
			});
			if (response.ok) {
				// const json = await response.json();
				onSuccess && onSuccess();
				showSuccess({
					message: getI18n().t(
						'Satisfaction set',
					),
				});
			} else {
				showError({
					message: getI18n().t(
						'Error changing satisfaction',
					),
				});
			}
		} catch (err) {
			console.error(err);
		}
	},
})), {
	name: 'ThreeSixty store',
	serialize: {
		immutable: Immutable,
	},
});

export default useThreeSixtyStore;
