import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import {StyledSectionDiv} from '../../../styled';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {LIMIT_PAYLOAD} from 'common/constants';
import ZendeskTableRow from './ZendeskTableRow';


function ZendeskTable({search, id}) {
	const [t] = useTranslation();
	const {fetchZendeskCases} = useThreeSixtyStore();
	const {zendesk, totalCount, accountNotFound} = useThreeSixtyStore((state) => ({
		zendesk: state.accountTables?.zendeskCases,
		totalCount: state.accountTables?.zendeskCases?.total_count,
		accountNotFound: state.accountNotFound,
	}));
	const zendeskData = zendesk?.zendesk_cases;
	const onLoadMore = useCallback((size) => {
		if (totalCount <= size) return;
		fetchZendeskCases(size + LIMIT_PAYLOAD, id);
	}, [id, totalCount, fetchZendeskCases]);

	const renderItem = useCallback((item, index) => (
		<ZendeskTableRow
			key={index}
			data={item}
		/>
	), []);

	useEffect(() => {
		if (accountNotFound) return;
		if (totalCount === 0 && zendesk?.zendesk_cases === null) return;

		const isAccountIdMatched = zendeskData?.length > 0 && id === zendeskData[0]?.account_id;
		const isZendeskFetched = zendesk !== null && isAccountIdMatched;
		const isZendeskFilterMatched = zendesk?.filters?.account_id === id && zendeskData === null;

		if (isZendeskFetched || isZendeskFilterMatched) return;

		fetchZendeskCases(LIMIT_PAYLOAD, id);
	}, [id, zendesk, zendeskData, accountNotFound, fetchZendeskCases, totalCount]);

	if (!zendesk || !zendesk?.zendesk_cases) {
		return (
			<StyledSectionDiv $endDiv $fullHeight>
				<Layout
					fullHeight
					fullWidth
					alignItems="center"
					justifyContent="center"
				>
					<Text>{t('No data')}</Text>
				</Layout>
			</StyledSectionDiv>
		);
	}

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Table
				style={{
					width: '100%',
					overflow: 'auto',
					height: '100%',
					borderRadius: '8px',
				}}
				items={zendeskData}
				renderItem={(item, index) => renderItem(item, index)}
				// rowHeight={70}
				virtualized
				onScrollDown={onLoadMore}
			>
				<Table.Row style={{position: 'sticky', top: '0px'}}>
					<Table.Cell width={85}>{t('#Ticket')}</Table.Cell>
					<Table.Cell width={60}>{t('Status')}</Table.Cell>
					<Table.Cell>{t('Subject')}</Table.Cell>
					<Table.Cell>{t('Client')}</Table.Cell>
					<Table.Cell>{t('Owner')}</Table.Cell>
					<Table.Cell>{t('Queue')}</Table.Cell>
					<Table.Cell width={50}>{t('SLA')}</Table.Cell>
					<Table.Cell width={75}>{t('Updated')}</Table.Cell>
					<Table.Cell width={75}>{t('Created')}</Table.Cell>
				</Table.Row>
			</Table>
		</StyledSectionDiv>
	);
}

ZendeskTable.propTypes = {
	search: PropTypes.string,
	id: PropTypes.string,
};

export default ZendeskTable;
