import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
// import useDevDocAdminStore from 'common/stores/devDocAdmin';

import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';
import {IconButton, Table, TrashBinIcon} from '@phonectas/unifon-ui-kit';

function AdminTableRow({item, onClick, onDelete, index, id}) {
	const [selectedUser, setSelectedUser] = useState(null);
	// const {deleteUser} = useDevDocAdminStore();
	const onClickRow = useCallback((item) => {
		onClick(item.id);
		setSelectedUser(id);
	}, [onClick, id]);

	const onDeleteUser = useCallback((e) => {
		// e.stopPropagation();
		onDelete(item.id);
	}, [onDelete, item.id]);

	return (
		<Table.Row
			key={item.id}
			index={index}
			onClick={() => onClickRow(item)}
			selected={item.id === selectedUser}
		>
			<Table.Cell>{item?.username}</Table.Cell>
			<Table.Cell>
				{
					getDateByCustomFormat(item?.updated_at, DATE_FORMATS.DD_MM_YY_HH_MM_SS)
				}
			</Table.Cell>
			<Table.Cell>
				{
					getDateByCustomFormat(item?.created_at, DATE_FORMATS.DD_MM_YY_HH_MM_SS)
				}
			</Table.Cell>
			<Table.Cell>
				<IconButton
					color="error"
					onClick={onDeleteUser}
				>
					<TrashBinIcon />
				</IconButton>
			</Table.Cell>
		</Table.Row>
	);
}

AdminTableRow.propTypes = {
	item: PropTypes.object,
	index: PropTypes.number,
	onClick: PropTypes.func,
	onDelete: PropTypes.func,
	id: PropTypes.number,
};

export default AdminTableRow;
