import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Button, Divider, Layout, TextField} from '@phonectas/unifon-ui-kit';
import useDevDocAdminStore from 'common/stores/devDocAdmin';

function AddUserForm({onClose}) {
	const [t] = useTranslation();
	const {createUser, fetchList} = useDevDocAdminStore();
	const [newUser, setNewUser] = useState({
		name: '',
		password: '',
	});
	const onNameChange = useCallback((e) => {
		setNewUser((prev) => ({
			...prev,
			name: e.target.value,
		}));
	}, []);

	const onPasswordChange = useCallback((e) => {
		setNewUser((prev) => ({
			...prev,
			password: e.target.value,
		}));
	}, []);

	const handleAddUser = useCallback(() => {
		createUser(newUser.name, newUser.password, () => {
			fetchList();
			onClose();
			setNewUser({
				name: '',
				password: '',
			});
		});
	}, [newUser, createUser, fetchList, onClose]);

	const onValid = newUser.name && newUser.password;
	return (
		<Layout fullWidth direction="column">
			<Layout fullWidth nowrap>
				<TextField
					value={newUser.name}
					fullWidth
					label={t('Name')}
					onChange={onNameChange}
					inputProps={{autoFocus: true}}
				/>
				<TextField
					fullWidth
					label={t('Password')}
					value={newUser.password}
					onChange={onPasswordChange}
					// inputProps={{minLength: 8}}
				/>
			</Layout>
			<Divider />
			<Layout fullWidth nowrap>
				<Button
					variant="primary"
					color="success"
					disabled={!onValid}
					onClick={handleAddUser}
				>
					{t('Add')}
				</Button>
				<Button color="secondary" onClick={onClose}>{t('Close')}</Button>
			</Layout>
		</Layout>
	);
}

AddUserForm.propTypes = {
	onClose: PropTypes.func,
};

export default AddUserForm;
