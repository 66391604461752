import sc from 'styled-components';

export const StyledTextDiv = sc.div`
	box-shadow: rgba(128, 131, 160, 0.2) 0px 1px 2px inset;
	border-radius: ${({$smBorderRadius}) => $smBorderRadius ? '4px' : '10px'};
	padding: 8px 10px;
	background-color: rgb(244, 244, 248);
	border: ${({$noBorder}) => $noBorder ? 'none' : 'solid 1px gray'};
	solid 1px gray;
	height: fit-content;
	width: 100%;
	white-space: ${({$whiteSpaceNormal}) => $whiteSpaceNormal ? 'normal' : 'pre-line'};
	word-break: break-word;
	overflow-wrap: break-word;
	min-height: 40px;
`;

export const StyledLabel = sc.label`
	color: rgb(151, 152, 169);
	user-select: none;
`;
