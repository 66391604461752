import React, {Suspense, useEffect, lazy} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {CloseIcon, Divider, IconButton, Layout, Text} from '@phonectas/unifon-ui-kit';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {StyledSimCardGrid} from '../../styled';
import UserInfoList from './UserInfoList';
import SimCardPlaceholderIcon from 'common/components/icons/SimCardPlaceholderIcon';

// Lazy load SimCardContainer
const SimCardContainer = lazy(() => import('common/components/SimCardContainer'));

function UserDialogContent({onClose, data}) {
	const [t] = useTranslation();
	const {fetchUserParentId} = useThreeSixtyStore();
	const parentData = useThreeSixtyStore((state) => state.accountTables?.parentId);
	useEffect(() => {
		if (data?.cdr_children_count > 0) {
			fetchUserParentId(20, data.account_id, data.cdr_subscription_id, true);
		}
	}, [data?.cdr_subscription_id, data.account_id, data?.cdr_children_count, fetchUserParentId]);

	return (
		<>
			<Layout nowrap alignItems="start" fullWidth justifyContent="space-between">
				<Layout nowrap fullWidth alignItems="center">
					<Layout direction="column" gap="none">
						<Layout alignItems="center" gap="sm" fullWidth>
							<Text variant="h1">
								{data.name}
							</Text>
						</Layout>
					</Layout>
				</Layout>
				<IconButton onClick={onClose}><CloseIcon /></IconButton>
			</Layout>
			<Divider fullWidth />
			<UserInfoList data={data} />
			<Divider fullHeigth fullWidth />
			{
				data?.cdr_children_count > 0 ? (
					<Layout fullWidth direction="column">
						<Text variant="h5">
							{t('Additional SIMs')}: {data?.cdr_children_count}
						</Text>
						<Suspense fallback={<div>Loading...</div>}>
							<StyledSimCardGrid style={{overflow: 'hidden'}}>
								{
									parentData?.customer_users?.map((item, index) => (
										<SimCardContainer key={index} data={item} />
									))
								}
							</StyledSimCardGrid>
						</Suspense>
					</Layout>
				) : (
					<Layout fullWidth justifyContent="center" direction="column">
						<Layout>
							<Text variant="h5">
								{t('Additional SIMs')}
							</Text>
						</Layout>
						<Layout fullWidth justifyContent="center" alignItems="center">
							<Layout fullWidth justifyContent="center" alignItems="center">
								<SimCardPlaceholderIcon />
							</Layout>
							<Layout fullWidth justifyContent="center" alignItems="center">
								<Text variant="body1">
									{t('No additional SIM cards')}
								</Text>
							</Layout>
						</Layout>
					</Layout>
				)
			}
		</>
	);
}

UserDialogContent.propTypes = {
	onClose: PropTypes.func,
	data: PropTypes.object,
};

export default React.memo(UserDialogContent);
