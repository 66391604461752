import sc from 'styled-components';

export const StyledPillBadge = sc.div`
    display: inline-block;
    padding: .25em .4em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    background-color: ${({bg}) => bg };
    color: #fff;
`;
