import sc from 'styled-components';

export const UserInfo = sc.div`
  display: flex;
  width: 100%;
`;

export const Row = sc.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    width: 100%;
`;

export const Column = sc.div`
    display: flex;
    gap: 5px;
    flex: 1;
    width: 100%;
    min-width: 300px;
    padding: 10px 0px;
    flex-wrap: wrap;
    flex-direction: column;
`;

