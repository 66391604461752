import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import objectSupport from 'dayjs/plugin/objectSupport';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/nb';
import {lang} from 'common/i18n';
import i18next from 'i18next';

const updatedNbCalendar = {
	calendar: {
		sameDay: '[I dag, ] HH:mm',
		nextDay: 'D MMM, HH:mm',
		nextWeek: 'ddd, HH:mm',
		lastDay: 'D MMM, HH:mm',
		lastWeek: 'D MMM, HH:mm',
		sameElse: 'D MMM, HH:mm',
	},
};

const updatedEnCalendar = {
	calendar: {
		sameDay: '[Today, ] HH:mm',
		nextDay: 'D MMM, HH:mm',
		nextWeek: 'ddd, HH:mm',
		lastDay: 'D MMM, HH:mm',
		lastWeek: 'D MMM, HH:mm',
		sameElse: 'D MMM, HH:mm',
	},
};

export const CURRENT_DATE_TIME_ISO = dayjs().toISOString();

export const DATE_FORMATS = {
	DD_MM: 'DD.MM',
	HH_MM: 'HH:mm',
	HH_MM_SS: 'HH:mm:ss',
	DDD: 'ddd',
	DD_MM_YY_HH_MM_SS: 'DD.MM.YY HH:mm:ss',
	MM_DD_YYYY_ddd_HH_mm: 'MM.DD.YYYY ddd HH:mm',
	YYYY_MM_DD_HH_mm_ss: 'YYYY-MM-DD HH:mm:ss',
	ISO: 'YYYY-MM-DDTHH:mm:ss.SSS',
	ISO_TZ: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
	MM_SS: 'mm:ss',
	YYYY_MM_DD: 'YYYY-MM-DD',
	DD_MMM_YYYY: 'DD MMM YYYY',
	DD_MM_YYYY: 'DD.MM.YYYY',
	DD_MMM_YYYY_HH_mm: 'DD MMM YYYY, HH:mm',
	DD_MM_YYYY_HH_mm: 'DD/MM/YYYY, HH:mm',
	DD_MM_YY: 'DD.MM.YY',
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);
dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs().localeData();
dayjs.locale(lang === 'en' ? 'en' : 'nb');
dayjs.updateLocale('en', {...updatedEnCalendar, weekStart: 1});
dayjs.updateLocale('nb', {...updatedNbCalendar, weekStart: 1});

export const TIMEZONE = 'Europe/Oslo';

dayjs.tz.setDefault(TIMEZONE);

/**
 * @param {String}        data
 * @param {String}        format
 * @return{String}
 */
export function getDateByCustomFormat(data, format) {
	if (!data) {
		return i18next.t('N/A');
	}
	return dayjs(data).format(format);
}

/**
 * @param {Number} timeInMilliseconds time in milliseconds
 * @return {String} result as '3m 34s' or '33s'
 */
export function formatDuration(timeInMilliseconds) {
	const duration = dayjs.duration(timeInMilliseconds);
	const hours = Math.floor(duration.asHours());
	const minutes = Math.floor(
		hours ?
			dayjs.duration(timeInMilliseconds - hours * 60 * 60 * 1000).asMinutes() :
			duration.asMinutes(),
	);
	const seconds = Math.floor(
		(hours || minutes) ?
			dayjs.duration(timeInMilliseconds - (hours * 60 * 60 + minutes * 60) * 1000)
				.asSeconds() :
			duration.asSeconds(),
	);

	return `${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}${seconds}s`;
}

/**
 * Converts an integer representing hours into a string with hours and minutes.
 * @param {number} totalHours - The total hours to convert.
 * @return {string} - The formatted string in "H hours M minutes" format.
 */
export function convertHoursSla(totalHours) {
	const sign = totalHours < 0 ? -1 : 1;
	const absTotalHours = Math.abs(totalHours);
	const days = Math.floor(absTotalHours / 24);
	const remainingHours = absTotalHours % 24;
	const hours = Math.floor(remainingHours);
	const minutes = Math.round((remainingHours - hours) * 60);

	let formattedTime = '';

	if (days > 0) {
		formattedTime += `${sign * days} d`;
	} else if (hours > 0) {
		formattedTime += `${sign * hours} ${i18next.t('h')}`;
	} else if (minutes > 0 || (hours === 0 && days === 0)) {
		formattedTime += `${sign * minutes} m`;
	}

	return formattedTime.trim();
}
