import React from 'react';
import PropTypes from 'prop-types';

import {Button, Table} from '@phonectas/unifon-ui-kit';

function CustomerConfigRow({onClick, data}) {
	console.log('data', data.value);
	return (
		<Table.Row>
			<Table.Cell>{data?.label ? data?.label : ''}</Table.Cell>
			<Table.Cell>{data?.value ? data?.value : ''}</Table.Cell>
			<Table.Cell>
				<Button disabled={data.disabled} variant="primary" onClick={onClick}>{data?.button}</Button>
			</Table.Cell>
		</Table.Row>
	);
}

CustomerConfigRow.propTypes = {
	onClick: PropTypes.func,
	data: PropTypes.object,
};

export default CustomerConfigRow;
