import sc from 'styled-components';
import {Dialog, Layout} from '@phonectas/unifon-ui-kit';

export const StyledDialog = sc(Dialog)`
	width: ${({width}) => width ? width : 690}px;
	max-height: 80vh;
	flex-wrap: nowrap;
	padding: 16px;
`;

export const StyledDialogBody = sc(Layout)`
	overflow: visible auto;
	padding: 3px 2px;
	overflow: auto;
`;

export const StyledSimCardGrid = sc.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(296.69px, 300px));
  grid-gap: 5px;
  max-width: 100%;
  width: 100%;
`;
