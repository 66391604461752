import React from 'react';
import PropTypes from 'prop-types';
import {DataUsageContainer, DataUsageFill} from './styled';
import {useTranslation} from 'react-i18next';
import {Layout, Text} from '@phonectas/unifon-ui-kit';
import {formatInfo} from 'utils/div';
import {convertMB} from 'utils/file';

const DataUsage = ({totalData, usedData, compact = false}) => {
	const [t] = useTranslation();
	const dataLimit = convertMB(totalData);
	const convertedUsedData = convertMB(usedData);
	// const usedDataInGB = parseDataUsage(usedData);
	// const converteddUsedData = formatFileSize(usedData);
	// const dataUsedPercentage = dataLimit ? (usedDataInGB / dataLimit) * 100 : 0;


	const dataUsedPercentage = dataLimit.valueInMB ? (convertedUsedData.valueInMB / dataLimit.valueInMB) * 100 : 0;
	const fillColor = dataUsedPercentage > 100 ? 'red' : 'rgba(135, 141, 230, 1)';
	const displayUsedData = convertedUsedData.valueInString ? convertedUsedData.valueInString : 'N/A';
	const displayDataLimit = dataLimit.valueInString ? dataLimit.valueInString : 'N/A';

	return (
		<Layout gap={compact ? 'none' : 'md'} direction={compact ? 'column' : undefined} fullWidth nowrap>
			<Layout fullWidth nowrap style={{maxWidth: '120px'}}>
				<Text variant={compact ? undefined : 'body1'} color="textSecondary">
					{ t('Data usage')}
				</Text>
			</Layout>
			<Layout fullWidth nowrap>
				{
					usedData !== null ? (
						<>
							{
								!compact ? (
									<>
										<Text variant="body1">
											{
												convertedUsedData?.valueInString && dataLimit?.valueInString ?
													`${convertedUsedData.valueInString} / ${dataLimit.valueInString}` :
													convertedUsedData?.valueInString ?
														convertedUsedData.valueInString :
														dataLimit?.valueInString ?
															dataLimit.valueInString :
															'n/a'
											}
										</Text>
										{
											!dataLimit || !convertedUsedData ? null : (
												<DataUsageContainer>
													<DataUsageFill
														style={{
															width: `${dataUsedPercentage}%`,
															backgroundColor: fillColor,
														}}
													/>
												</DataUsageContainer>
											)
										}
									</>
								) : (
									<Layout alignItems="center" nowrap>
										<Text>
											{
												convertedUsedData?.valueInString && dataLimit?.valueInString ?
													`${displayUsedData}/${displayDataLimit}` :
													convertedUsedData?.valueInString ?
														displayUsedData :
														dataLimit?.valueInString ?
															displayDataLimit :
															'n/a'
											}
										</Text>
										{
											!dataLimit || !convertedUsedData ? null : (
												<DataUsageContainer>
													<DataUsageFill
														style={{
															width: `${dataUsedPercentage}%`,
															backgroundColor: fillColor,
														}}
													/>
												</DataUsageContainer>
											)
										}
									</Layout>
								)
							}
						</>
					): (
						<>
							<Text variant={compact ? undefined : 'body1'}>
								{formatInfo(usedData)}
							</Text>
						</>
					)
				}
			</Layout>
		</Layout>
	);
};

DataUsage.propTypes = {
	totalData: PropTypes.number.isRequired,
	usedData: PropTypes.number,
	compact: PropTypes.bool,
};

export default DataUsage;
