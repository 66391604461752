import React from 'react';
import PropTypes from 'prop-types';
import BillingGroupTable from './tables/BillingGroupTable';
import UsersTable from './tables/UsersTable';
import ContactsTable from './tables/ContactsTable';
import InvoiceTable from './tables/InvoicesTable';
import ZendeskTable from './tables/ZendeskTable';
import BedriftsnettTable from './tables/BedriftsnettTable';
import CustomerConfig from './tables/CustomerConfig';

function CustomerTabs({activeTab, search, customerId, userTableFilter, setUserTableFilter}) {
	return (
		<>
			{activeTab === '1' && <UsersTable
				search={search}
				id={customerId}
				userTableFilter={userTableFilter}
				setUserTableFilter={setUserTableFilter}
			/>}
			{activeTab === '2' && <BillingGroupTable id={customerId} />}
			{activeTab === '3' && <ContactsTable id={customerId} />}
			{activeTab === '4' && <InvoiceTable id={customerId} />}
			{activeTab === '5' && <ZendeskTable id={customerId} />}
			{activeTab === '6' && <BedriftsnettTable id={customerId} />}
			{activeTab === '7' && <CustomerConfig id={customerId} />}
		</>
	);
}

CustomerTabs.propTypes = {
	activeTab: PropTypes.string.isRequired,
	search: PropTypes.string,
	customerId: PropTypes.string.isRequired,
	userTableFilter: PropTypes.bool,
	setUserTableFilter: PropTypes.func,
};

export default CustomerTabs;
