/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function StatusOk({priColor = '#47AC45', secColor = 'white'}) {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_52_79)">
				<path d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z" fill={`${priColor}`}/>
				<path fillRule="evenodd" clipRule="evenodd" d="M12.5614 5.44392L6.71298 11.5341L2.78516 7.44392L3.91859 6.35547L6.71298 9.26534L11.428 4.35547L12.5614 5.44392Z" fill={`${secColor}`}/>
			</g>
			<defs>
				<clipPath id="clip0_52_79">
					<rect width="15" height="15" fill={`${secColor}`}/>
				</clipPath>
			</defs>
		</svg>

	);
}

StatusOk.propTypes = {
	priColor: PropTypes.string,
	secColor: PropTypes.string,
};

export default StatusOk;
