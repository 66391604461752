import React, {useEffect, useState} from 'react';
import {
	useNavigate,
	useParams,
} from 'react-router-dom';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';

import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {Layout, Page} from '@phonectas/unifon-ui-kit';
import {phoneNumberWithoutSpaces} from 'utils/number';
import UserNotFound from './components/UserNotFound';

function RedirectFromBn(props) {
	const navigate = useNavigate();
	const params = useParams();
	const {fetchAccountByPhone, fetchUser} = useThreeSixtyStore();
	const {user, account, isLoading, accountNotFound} = useThreeSixtyStore((state) => ({
		user: state.bnRedirect?.user,
		account: state.bnRedirect?.account?.id,
		accountNotFound: state.accountNotFound,
		isLoading: state.isLoading,
	}));
	const [isDataReady, setIsDataReady] = useState(false);

	useEffect(() => {
		if (params.numb) {
			fetchAccountByPhone(phoneNumberWithoutSpaces(params.numb), () => {
				fetchUser(phoneNumberWithoutSpaces(params.numb), () => {
					setIsDataReady(true);
				});
			});
		}
	}, [params.numb, fetchAccountByPhone, fetchUser]);

	useEffect(() => {
		if (isDataReady && account && user) {
			navigate(`/Unifon360/${account}`);
		}
	}, [isDataReady, account, user, navigate]);

	return (
		<Page>
			<Layout style={{paddingTop: '50px'}} fullWidth>
				<LoaderPortal isLoading={isLoading} />
				{
					accountNotFound && (
						<Page.Section>
							<UserNotFound numb={params.numb} />
						</Page.Section>
					)
				}
			</Layout>
		</Page>
	);
}

export default RedirectFromBn;
