import BnCall from './CustomerEvents/components/eventTypes/BnCall';
import ErateSystemCall from './CustomerEvents/components/eventTypes/ErateSystemCall';
import ErateSystemSms from './CustomerEvents/components/eventTypes/ErateSystemSms';
import ThreeSixtyComments from './CustomerEvents/components/eventTypes/ThreeSixtyComments';
import ThreeSixtyNewCustomerResp from './CustomerEvents/components/eventTypes/ThreeSixtyNewCustomerResp';
import ThreeSixtyNewSaf from './CustomerEvents/components/eventTypes/ThreeSixtyNewSaf';

function getEventType(eventType) {
	switch (eventType) {
	case 'THREESIXTY_COMMENT':
		return ThreeSixtyComments;
	case 'THREESIXTY_NEW_CUSTOMER_RESP':
		return ThreeSixtyNewCustomerResp;
	case 'BN_CALL_LOG':
		return BnCall;
	case 'ERATE_CALL_LOG':
		return ErateSystemCall;
	case 'ERATE_SYSTEM_SMS':
		return ErateSystemSms;
	case 'THREESIXTY_NEW_SAF_VALUE':
		return ThreeSixtyNewSaf;
		// Add more cases as needed
	default:
		return null;
	}
}

export default getEventType;
