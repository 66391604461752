import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useMatch, useNavigate} from 'react-router-dom';

import {StyledListItemIcon, StyledListItemText, StyledNavLink} from '../styles';

/**
 * NavLink component.
 * @param {Object} props - Component props.
 * @param {string} props.to - The path that the NavLink points to.
 * @param {Function} props.Icon - The icon component to display.
 * @param {string} props.size - The size of the icon.
 * @param {string} props.text - The text to display.
 * @param {boolean} props.expand - Whether to expand the NavLink.
 * @return {JSX.Element} The rendered NavLink component.
 */
function NavLink({to, Icon, size, text, expand}) {
	const match = useMatch(to);
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => {
		navigate(to);
	}, [navigate]);
	return (
		<StyledNavLink
			to={to}
			className={match ? 'active' : ''}
			onClick={handleOnClick}
		>
			<StyledListItemIcon>
				<Icon size={size} />
			</StyledListItemIcon>
			{expand && (
				<StyledListItemText>{text}</StyledListItemText>
			)}
		</StyledNavLink>
	);
}

NavLink.propTypes = {
	to: PropTypes.string.isRequired,
	Icon: PropTypes.elementType.isRequired,
	size: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	expand: PropTypes.bool.isRequired,
};

export default NavLink;
