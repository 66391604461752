import sc from 'styled-components';

import {Layout} from '@phonectas/unifon-ui-kit';

export const StyledCustomerDevices = sc(Layout)`
	background-color: #F3F3F5;
	border-radius: ${({theme}) => theme.borderRadius * 2}px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	height: 100px;
	width: 180px;
	padding: 10px;
`;

export const StyledDeviceContainer = sc.div`
    display: flex;
	flex-wrap: wrap;
	gap: 10px;
	width: 100%;
	padding: 16px;
	// justify-content: space-between;

	// @media (max-width: 1873px) {
	// 	justify-content: start;
	// 	gap: 5px;
	// }
	// @media (max-width: 1000px) {
	// 	padding: 8px;
	// }
`;
